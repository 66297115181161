import { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Nav from "../user/userComponents/Navbar";
import risk from "../../assets/images/questionnaire/risk.png";
import sick from "../../assets/images/questionnaire/sick.png";

const QuestionStart = ({ history, match, user }) => {
	useEffect(() => {
		if (!user || !user.questionnaire) return history.push("/");
	}, []);

	if (!user || !user.questionnaire) return <Redirect to="/" />;
	else
		return (
			<div style={{ backgroundColor: "#0a1a30", minHeight: "100vh", textAlign: "center" }}>
				<Nav history={history} match={match} />
				<div className="mt-5">
					<img src={risk} alt="risk" className="img-fluid" style={{ margin: "25px auto", width: "80%" }} />
					<img src={sick} alt="sick" className="img-fluid" style={{ width: "90%" }} />
					<div className="d-flex justify-content-between px-3 mt-5 pb-5">
						<button
							type="button"
							style={{
								borderRadius: "10px",
								width: "100%",
								height: "42px",
								border: "none",
								fontWeight: "bold",
								color: "#001d34",
								backgroundColor: "#ffffff",
								marginRight: "10px"
							}}
							onClick={() => history.push("/user/dashboard")}
						>
							SKIP THE TEST
						</button>
						<button
							type="button"
							style={{
								borderRadius: "10px",
								width: "100%",
								height: "42px",
								border: "none",
								fontWeight: "bold",
								color: "#ffffff",
								backgroundColor: "#c69936",
								marginLeft: "10px"
							}}
							onClick={() => history.push("/questionnaire/ipp/ensure")}
						>
							LET'S GO!
						</button>
					</div>
				</div>
			</div>
		);
};

const mapStateToProps = state => {
	return {
		user: state.user.user
	};
};

export default connect(mapStateToProps)(QuestionStart);
