import { FETCH_CODE, CODE_ERROR } from '../actions/type';

const initState = {
    code: null,
    name: null,
    specialty: null,
    address: null,
    number: null,
    error: false,
    show: false,
    sku: null,
};

const codeReducer = (state = initState, action) => {
    switch(action.type) {
        case FETCH_CODE:
            return {
                ...state,
                code: action.payload.code,
                name: action.payload.name,
                specialty: action.payload.specialty,
                address: action.payload.address,
                number: "-",
                sku: action.payload.sku,
                show: true
            };
        
        case CODE_ERROR:
            return {
                ...state,
                error: true,
                show: false
            };
        default:
            return state;
    };
};

export default codeReducer;