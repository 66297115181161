import React from "react";
import { connect } from "react-redux";
import { logoutUser } from "../../../redux/actions/userActions";
import abbottSmall from "../../../assets/images/abbottSmall.png";

const Nav = ({ history, match, logout, isLoading }) => {
	const mechanics = localStorage.getItem("mechanics");

	if (mechanics && mechanics !== "whatsapp") return (
		<nav className="navbar navbar-dark fixed-top">
			{match.params.route === "dashboard" && !isLoading ? (
				<button onClick={e => logout()} className="btn">
					<i className="fas fa-sign-out-alt" style={{ color: "white" }}></i>
				</button>
			) : null}
			{match.params.route === "login" || match.params.route === "dashboard" || match.params.route === "terms" ? null : (
				<button className="btn" onClick={() => history.push("/user/dashboard")}>
					<i className="fas fa-arrow-left" style={{ color: "white" }}></i>
				</button>
			)}
			{match.params.route === "terms" ? (
				<button className="btn" onClick={() => history.goBack()}>
					<i className="fas fa-arrow-left" style={{ color: "white" }}></i>
				</button>
			) : null}

			<div className="ml-auto">
				<div className="navbar-brand">
					<img src={abbottSmall} alt="abbott nav" className="img-fluid abbott-logo" />
				</div>
			</div>
		</nav>
	);
	else return null;
};

const mapStateToProps = state => {
	return {
		isLoading: state.user.isLoading
	};
};

const mapDispatchToProps = dispatch => {
	return {
		logout: () => dispatch(logoutUser())
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
