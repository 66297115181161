import React , { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkSession } from '../../../redux/actions/salesRepActions';
import jsQR from 'jsqr';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// import SelectSearch from 'react-select-search';
// import 'react-select-search/style.css';
import Select from 'react-select';
import axios from 'axios';
import medical from '../../../assets/images/medical.png';
import scanner from '../../../assets/images/scanner.png';

const mySwal = withReactContent(Swal);
class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected : false,
            playing : false,
            loading : false,
            button : "scan",
            drName : "",
            drCode : "",
            masterCode : "",
            masterCodes : [],
            data : []
        };
        this.handleChange = this.handleChange.bind(this);
        // this.handleCode = this.handleCode.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleButton = this.handleButton.bind(this);
        this.handleVideo = this.handleVideo.bind(this);
        this.handleVoucher = this.handleVoucher.bind(this);
        this.handleTag = this.handleTag.bind(this);
        this.handleDoctor = this.handleDoctor.bind(this);
    };
    timer;

    componentDidMount() {
        this.props.checkSession();
    };

    componentWillUnmount() {
        if (this.video) {
            let stream = this.video.srcObject;
            clearTimeout(this.timer);
            if (stream) stream.getTracks().forEach(x => x.stop());
        }
        clearTimeout(this.timer);
        this.timer = null;
    };

    tick = () => {
        if (this.video.readyState === this.video.HAVE_ENOUGH_DATA && this.state.masterCode === "") {
            const ctx = this.canvas.getContext("2d");
            ctx.drawImage(this.video , 0 , 0 , 360 , 320);
            let imageData = ctx.getImageData(0 , 0 , 360 , 320);
            let code = jsQR(imageData.data , 360 , 320);

            if (code && code.data) {
                this.drawLine(code.location.topLeftCorner, code.location.topRightCorner , "red");
                this.drawLine(code.location.topRightCorner, code.location.bottomRightCorner , "red");
                this.drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner , "red");
                this.drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner , "red");
                
                clearTimeout(this.timer);
                this.timer = null;
                this.setState({...this.state , masterCode : code.data , loading : true});

                axios.post('/api/salesRep/checkCode' , {masterCode : code.data})
                .then(res => {
                    if (this.state.masterCodes.includes(res.data.code)) {
                        this.setState({...this.state , masterCode : "" , loading : false});
                        // mySwal.fire("Error" , "This code has already been scanned" , "error")
                        // .then(() => this.setState({...this.state , masterCode : "" , loading : false}));
                    } else {
                        // this.setState({...this.state , loading : false});
                        mySwal.fire({
                            title : "CONFIRMATION",
                            html : `Please confirm your scanned item.<br /><strong>${res.data.brand.toUpperCase()} (${res.data.unit} units)</strong>`,
                            icon : "info",
                            allowOutsideClick : false,
                            showCancelButton : true,
                            cancelButtonText : "No",
                            cancelButtonColor : "#d33",
                            confirmButtonText : "Yes",
                        })
                        .then(result => {
                            if (result.isConfirmed) {
                                const thisBrand = this.state.data.filter(x => x.brand === res.data.brand)[0];

                                if (thisBrand) {
                                    const data = this.state.data.map(x => {
                                        if (x.brand === res.data.brand) {
                                            x.unit += res.data.unit
                                        }
                                        return x;
                                    });

                                    this.setState({
                                        ...this.state,
                                        data,
                                        masterCodes : [...this.state.masterCodes , res.data.code],
                                        loading : false,
                                        masterCode : ""
                                    });
                                } else {
                                    this.setState({
                                        ...this.state,
                                        data : [...this.state.data , {brand : res.data.brand , unit : res.data.unit}],
                                        masterCodes : [...this.state.masterCodes , res.data.code],
                                        loading : false,
                                        masterCode : ""
                                    });
                                }
                            } else {
                                this.setState({...this.state , loading : false , masterCode : ""});
                            }
                        });
                    }
                })
                .catch(err => {
                    this.setState({...this.state , loading : false});
                    console.log(err);
                    mySwal.fire("Error" , err.response.data.error , "error")
                    .then(() => this.setState({...this.state , masterCode : ""}));
                });
            }
        }
        this.timer = setTimeout(this.tick);
    };

    drawLine = (begin , end , color) => {
        let ctx = this.canvas.getContext("2d");
        ctx.beginPath();
        ctx.moveTo(begin.x , begin.y);
        ctx.lineTo(end.x , end.y);
        ctx.lineWidth = 2;
        ctx.strokeStyle = color;
        ctx.stroke();
    };

    handleChange(e) {
        this.setState({
            ...this.state,
            [e.target.id] : (e.target.value).toUpperCase()
        });
    };

    handleDoctor(e) {
        this.setState({
            ...this.state,
            drCode : e.value,
            drName : e.label
        });
    };

    // handleCode(code) {
    //     this.setState({
    //         ...this.state,
    //         drCode : code
    //     });
    // };

    handleSelect(e) {
        e.preventDefault();
        if (this.state.drCode && this.state.drName) {
            this.setState({
                ...this.state,
                selected : true
            });
        } else {
            mySwal.fire("" , "Please Select 1 Doctor to Proceed" , "info");
        }
    };

    handleButton(e) {
        if (this.state.button !== e.target.id) {
            if (this.video) {
                let stream = this.video.srcObject;
                clearTimeout(this.timer);
                if (stream) stream.getTracks().forEach(x => x.stop());
            }
            this.setState({
                ...this.state,
                button : e.target.id,
                playing : false
            });
        }
    };

    handleVideo(e) {
        if (this.video) {
            navigator.mediaDevices.getUserMedia({video : {facingMode : "environment"} , audio : false})
            .then(stream => {
                this.video.srcObject = stream;
                this.video.play();
                requestAnimationFrame(this.tick);
                this.setState({
                    ...this.state,
                    playing : true
                });
            })
            .catch(err => mySwal.fire(err.name , err.message , "error"));
        }
    };

    handleVoucher(e) {
        e.preventDefault();
        const { masterCode } = this.state;
        this.setState({...this.state , loading : true});
        axios.post('/api/salesRep/checkCode' , {masterCode})
        .then(res => {
            if (this.state.masterCodes.includes(res.data.code)) {
                this.setState({...this.state , loading : false , masterCode : ""});
                mySwal.fire("Error" , "This code has already been scanned" , "error");
            } else {
                this.setState({...this.state , loading : false , masterCode : ""});
                mySwal.fire({
                    title : "CONFIRMATION",
                    html : `Please confirm your scanned item.<br /><strong>${res.data.brand.toUpperCase()} (${res.data.unit} units)</strong>`,
                    icon : "info",
                    allowOutsideClick : false,
                    showCancelButton : true,
                    cancelButtonText : "No",
                    cancelButtonColor : "#d33",
                    confirmButtonText : "Yes",
                })
                .then(result => {
                    if (result.value && result.isConfirmed) {
                        const thisBrand = this.state.data.filter(x => x.brand === res.data.brand)[0];
                        if (thisBrand) {
                            const data = this.state.data.map(x => {
                                if (x.brand === res.data.brand) {
                                    x.unit += res.data.unit
                                }
                                return x;
                            });
                            this.setState({
                                ...this.state,
                                data,
                                masterCodes : [...this.state.masterCodes , res.data.code],
                                loading : false,
                                masterCode : ""
                            });
                        } else {
                            this.setState({
                                ...this.state,
                                data : [...this.state.data, {brand : res.data.brand , unit : res.data.unit}],
                                masterCodes : [...this.state.masterCodes , res.data.code],
                                loading : false,
                                masterCode : ""
                            });
                        }
                    }
                })
            }
        })
        .catch(err => {
            this.setState({...this.state , loading : false , masterCode : ""});
            mySwal.fire("Error" , err.response.data.error , "error");
        });
    };

    handleTag(e) {
        const { masterCodes , data } = this.state;
        const doctorCode = this.state.drCode;
        const salesRepId = this.props.salesRep.salesRep._id;
        const taggedBy = this.props.salesRep.salesRep.taggedBy;
        this.setState({...this.state , loading : true});
        if (!masterCodes.length) {
            this.setState({...this.state , loading : false});
            return mySwal.fire("" , "Please tag at least one or more voucher" , "info");
        } else {
            // console.log({masterCodes , doctorCode});
            if (this.state.playing) {
                if (this.video) {
                    let stream = this.video.srcObject;
                    clearTimeout(this.timer);
                    if (stream) stream.getTracks().forEach(x => x.stop());
                }
                this.setState({
                    ...this.state,
                    playing : false
                });
            }
            const sendThis = {masterCodes , doctorCode , salesRepId , taggedBy};
            console.log(sendThis);
            axios.post('/api/salesRep/tagQR' , sendThis)
            .then(res => {
                if (res.data && res.data.status === "Success") {
                    this.setState({...this.state , loading : false});
                    mySwal.fire({
                        title : "Done!",
                        html : `
                            <div>
                                <p>Your Voucher Tagging is Successful!</p>
                                <hr />
                                <div class="mt-3">
                                    <p class="m-0 p-0 text-left"><small>${new Date().toLocaleString().toString()}</small></p>
                                    
                                    ${data.map(x => {
                                        return (
                                            `
                                            <div key=${x.brand} class="d-flex justify-content-between">
                                                <div>
                                                    <h6>${x.brand.toUpperCase()}</h6>
                                                </div>
        
                                                <div>
                                                    <h6>${x.unit} units</h6>
                                                </div>
                                            </div>
                                            `
                                        )
                                    })}
                                    
                                </div>
                            </div>
                        `,
                        allowOutsideClick : false,
                    })
                    .then(result => {
                        if (result.value && result.isConfirmed) {
                            this.setState({
                                selected : false,
                                playing : false,
                                loading : false,
                                button : "scan",
                                drName : "",
                                drCode : "",
                                masterCode : "",
                                masterCodes : [],
                                data : [] 
                            });
                        }
                    })
                }
            })
            .catch(err => {
                console.log(err);
                this.setState({...this.state , loading : false});
                mySwal.fire("Error" , err.response.data.error , "error");
            });
        }
    };

    render() {
        // const buttonStyle = text => {
        //     if (text === this.state.button) {
        //         return {color : "#f7f7f7" , backgroundColor : "#0a1a30"};
        //     } else {
        //         return {color : "#0a1a30" , backgroundColor : "#f7f7f7"};
        //     }
        // };

        const renderScan = () => {
            if (this.state.button === "scan") {
                return (
                    <div className="text-center">
                        <video style={{display : "none"}} ref={ref => this.video = ref} autoPlay playsInline muted></video>
                        <canvas width={this.state.playing ? "240px" : ("0px")} height={this.state.playing ? "180px" : "0px"} ref={ref => this.canvas = ref}></canvas>
                        
                        {
                            this.state.playing ? (
                                null
                            ) : (
                                <img src={scanner} alt="scanner img" className="img-fluid" />
                            )
                        }
                        
                        <div>
                            <p style={{fontSize : "12px"}}><small>Please scan the master code on top of the surface.</small></p>
                            {
                                this.state.playing ? (
                                    null
                                ) : (
                                    <button onClick={this.handleVideo} className="btn" style={{backgroundColor : "#0a1a30" , color : "white" , width : "120px" , fontWeight : "bold" , fontSize : "20px"}}>SCAN</button>
                                )
                            }
                        </div>
                    </div>
                )
            }/* else if (this.state.button === "manual") {
                return (
                    <form onSubmit={this.handleVoucher}>
                        <div className="form-group">
                            <label htmlFor="masterCode">Master Code:</label>
                            <input type="text" id="masterCode" name="masterCode" placeholder="Master Code" className="form-control" onChange={this.handleChange} value={this.state.masterCode} required />
                        </div>

                        <div>
                            <p style={{fontSize : "12px"}}><small>Please enter the master code on top of the surface.</small></p>
                            {
                                this.state.loading ? (
                                    <div className="spinner-border text-dark" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                ) : (
                                    <input type="submit" value="SUBMIT" className="btn" style={{backgroundColor : "#0a1a30" , color : "white" , width : "120px" , fontWeight : "bold" , fontSize : "20px"}}/>
                                )
                            }
                        </div>
                    </form>
                )
            }*/
        };

        const doctors = this.props.salesRep.salesRep ? (this.props.salesRep.salesRep.doctors.map(doc => {return {label : doc.name , value : doc.code}})) : (null);

        const renderPage = () => {
            if (!this.state.selected) {
                return (
                    <div>
                        <h3 className="mt-4">{this.props.salesRep.salesRep.name}</h3>
                        <form className="mt-5 mb-1" onSubmit={this.handleSelect}>
                            <div className="form-group px-5 text-center">
                                <label htmlFor="drName" className="font-weight-bold">DOCTOR NAME</label>

                                {/* <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fas fa-user-md"></i></span>
                                    </div>
                                    <select id="drName" name="drName" defaultValue="initValue" onChange={this.handleChange} className="custom-select text-center input-box" required>
                                        <option disabled value="initValue">Select a Doctor</option>
                                        {
                                            this.props.salesRep.salesRep.doctors.map(dr => <option key={dr.code} value={dr.name} onClick={() => this.handleCode(dr.code)}>{dr.name}</option>)
                                        }
                                    </select>
                                </div> */}
                                    <Select options={doctors} onChange={this.handleDoctor} />
                                {/* <SelectSearch placeholder="Select a Doctor" search options={doctors} emptyMessage="Not Found" /> */}
                            </div>

                            <div className="form-group px-5">
                                <label htmlFor="drCode" className="font-weight-bold">DOCTOR CODE</label>

                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fas fa-tag"></i></span>
                                    </div>
                                    <input id="drCode" name="drCode" placeholder="Enter Doctor Code" className="form-control text-center input-box" value={this.state.drCode} readOnly />
                                </div>
                            </div>

                            <div className="mt-5">
                                <img src={medical} alt="medical img" className="img-fluid" />
                            </div>

                            <div style={{marginTop : "5vh" , paddingLeft : "8vw" , paddingRight : "8vw"}}>
                                <input type="submit" value="NEXT" className="btn form-control big-button" />
                            </div>
                        </form>
                    </div>
                );
            } else {
                return (
                    <div style={{paddingLeft : "10vw" , paddingRight : "10vw"}}>
                        <h4 className="font-weight-bold mt-3">VOUCHER TAGGING</h4>
                        <h6>Doctor Code: <strong>{this.state.drCode}</strong></h6>
                        <h6>Doctor Name: <strong>{this.state.drName}</strong></h6>

                        <div className="mt-4">
                            <div className="text-right">
                                <div>
                                    {/* <button id="scan" name="scan" onClick={this.handleButton} className="pill-btn" style={buttonStyle("scan")} >SCAN</button> */}
                                    {/* <button id="manual" name="manual" onClick={this.handleButton} className="pill-btn" style={buttonStyle("manual")} >MANUAL</button> */}
                                </div>
                            </div>

                            <div className="card">
                                <div className="card-header" style={{backgroundColor : "#0a1a30"}}>
                                    <h5 className="card-title p-0 m-0" style={{fontSize : "16px" , color : "white"}}>PLEASE SCAN VOUCHER CODE</h5>
                                </div>

                                <div className="card-body">
                                    {renderScan()}
                                </div>
                            </div>

                            <div className="px-3">
                                <hr />
                            </div>

                            <div className="mb-5">
                                <p className="m-0 p-0 text-left"><small>{new Date().toLocaleString().toString()}</small></p>
                                <ul className="list-group">
                                    {
                                        this.state.data.map(x => {
                                            return (
                                                <li key={x.brand} className="list-group-item d-flex justify-content-between">
                                                    <div>
                                                        <h6>{x.brand.toUpperCase()}</h6>
                                                    </div>

                                                    <div>
                                                        <h6>{x.unit} units</h6>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>

                                <div className="mt-3">
                                    {
                                        this.state.loading ? (
                                            <div className="spinner-border text-dark" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        ) : (
                                            this.state.masterCodes.length ? (
                                                <button onClick={this.handleTag} className="btn big-button form-control">DONE</button>
                                            ) : (
                                                null
                                            )
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        };

        if (this.props.salesRep.isLoading) {
            return (
                <div className="body-contents text-center">
                    <div className="spinner-border text-dark" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )
        } else if (!this.props.salesRep.salesRep) {
            return (
                <div className="body-contents text-center">
                    <h3 className="mt-5">Please <Link to="/salesRep/login">login</Link>.</h3>
                    {
                        this.props.salesRep.error ? (
                            <p>{this.props.salesRep.error}</p>
                        ) : (
                            null
                        )
                    }
                </div>
            )
        } else {

            return (
                <div id="sr-dashboard" className="text-center">
                    {renderPage()}
                </div>
            );
        }
    };
};

const mapStateToProps = state => {
    return {
        salesRep : state.salesRep
    };
};

const mapDispatchToProps = dispatch => {
    return {
        checkSession : () => dispatch(checkSession())
    };
};

export default connect(mapStateToProps , mapDispatchToProps)(Dashboard);