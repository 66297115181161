import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import shopee from "../../../assets/images/shopee.png";
import lazada from "../../../assets/images/lazada.png";

class Checkout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			time: 5 * 60 * 1000,
			redeemed: false
		};
	}

	_isMounted = false;
	timer = null;

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted) {
			if (!this.props.user.isAuthenticated || !this.props.user.user) {
				this.props.history.push("/user/dashboard");
			} else {
				this.timer = setInterval(() => {
					this.setState({
						...this.state,
						time: this.state.time - 100
					});
					// console.log(this.state.time);
					if (this.state.time <= 0) {
						clearInterval(this.timer);
						this.timer = null;
						this.setState({
							...this.state,
							redeemed: true
						});
					}
				}, 100);
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		clearInterval(this.timer);
		this.timer = null;
	}

	render() {
		const toClock = () => {
			const { time } = this.state;
			let date = new Date(time);
			const minutes = date.getUTCMinutes();
			const seconds = date.getUTCSeconds();
			return `${minutes < 10 ? "0" + minutes : minutes} : ${seconds < 10 ? "0" + seconds : seconds}`;
		};

		const progresBar = () => {
			const full = 180000; // 3 * 60 * 1000
			return { width: `${(this.state.time / full) * 100}%` };
		};

		const progressColor = () => {
			const two = 2 * 60 * 1000;
			const one = 60 * 1000;

			if (this.state.time >= two && this.state.time >= one) {
				return "progress-bar bg-success";
			} else if (this.state.time <= two && this.state.time >= one) {
				return "progress-bar bg-warning";
			} else if (this.state.time <= two && this.state.time <= one) {
				return "progress-bar bg-danger";
			} else {
				return "progress-bar bg-dark";
			}
		};

		if (!this.props.user.user) {
			return <Redirect to="/user/dashboard" />;
		} else {
			if (this.state.redeemed || this.props.user.user.usrStatus === "redeemed") {
				return (
					<div className="body-contents text-center">
						<div style={{ marginTop: "18vh" }} id="checkout-container">
							<i id="checkout-check" className="fas fa-check-circle"></i>

							<div>
								<h4>Done !</h4>
								<p>Your Voucher redemption is Successful!</p>

								<Link to="/user/dashboard" className="btn">
									DONE
								</Link>
							</div>
						</div>
					</div>
				);
			} else {
				return (
					<div className="body-contents text-center">
						<h2 className="font-weight-bold mt-4">REDEMPTION</h2>

						<div className="mt-5" id="checkout-container">
							<i id="checkout-check" className="fas fa-check-circle"></i>

							<div>
								{this.props.user.user.doctor.promoMechanics === "foc" ? (
									<p>
										Present this and get
										<br />
										<span style={{ fontSize: "26px", fontWeight: "bold" }}>FREE</span>
										<br />2 unit x{" "}
										{/ensure/gi.test(this.props.user.user.brand)
											? "Ensure® Gold™ Liquid Vanilla RPB 220ml"
											: "Glucerna Liquid Vanilla RPB 220ml"}
									</p>
								) : (
									<h4>Redemption Code</h4>
								)}

								<div className="mt-3 mb-2 text-center">
									{this.props.user.user.redeemStore === "ECOMM" ||
									this.props.user.user.redemption.storeType === "ECOMM" ? (
										<div>
											<h2>
												<strong>{this.props.user.user.voucherData}</strong>
											</h2>
											<div className="text-center mt-5">
												<h4 style={{ fontWeight: "bold" }}>REDEEM NOW AT </h4>
												<a href={this.props.user.user.redeemUrl || this.props.user.user.redemption.url}>
													<img
														src={
															this.props.user.user.redeemUrl.includes("lazada") ||
															this.props.user.user.store.storeName === "LAZADA"
																? lazada
																: shopee
														}
														alt="ecomm"
														className="img-fluid"
														style={{ maxWidth: "80px" }}
													/>
												</a>
											</div>
										</div>
									) : (
										<div id="voucher-image-container">
											<img src={this.props.user.user.voucherData} alt="barcode" className="img-fluid m-auto" />
											<div id="voucher-protector"></div>
										</div>
									)}
								</div>

								{this.props.user.user.doctor.promoMechanics === "foc" ? null : (
									<p>Present this redemption code to the retailer to redeem reward.</p>
								)}
							</div>
						</div>

						{this.props.user.user.doctor.promoMechanics === "foc" ? (
							<div className="text-center mt-4">
								<h6>
									<strong>T&amp;C applies*</strong>
								</h6>
								<p>
									Only valid upon purchase of
									<br />1 unit of 400g {/ensure/gi.test(this.props.user.user.brand) ? "Ensure® Gold™" : "Glucerna"}
									{/* <br />3 units of{" "}
									{/ensure/gi.test(this.props.user.user.brand)
										? "Ensure® Gold™ Liquid RPB 220ml"
										: "Glucerna Liquid RPB 220ml"} */}
								</p>
							</div>
						) : null}

						<div className="mt-5 mb-5" id="timer-container">
							<h6>EXPIRE IN:</h6>

							<div>
								<div
									className="progress"
									style={{
										height: "45px",
										fontWeight: "bold",
										fontSize: "20px"
									}}
								>
									<div
										className={progressColor()}
										role="progressbar"
										style={progresBar()}
										aria-valuemin="0"
										aria-valuemax="100"
									>
										{toClock()}
									</div>
								</div>
							</div>
						</div>

						<div>
							<p>
								<small>
									Abbott Nutrition Careline at 1800-88-6233
									<br />
									(Monday-Friday 9am-6pm)
								</small>
							</p>
						</div>
					</div>
				);
			}
		}
	}
}

const mapStateToProps = state => {
	return {
		user: state.user
	};
};

export default connect(mapStateToProps, null)(Checkout);
