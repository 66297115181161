import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Nav from "../user/userComponents/Navbar";
import axios from "axios";
import move from "../../assets/images/questionnaire/move.png";
import walk from "../../assets/images/questionnaire/walk.png";
import sit from "../../assets/images/questionnaire/sit.png";
import climb from "../../assets/images/questionnaire/climb.png";
import fall from "../../assets/images/questionnaire/fall.png";

const qi = [move, walk, sit, climb, fall];

const Questionnaire = ({ history, match, user, updateQuestionnaire }) => {
	const [questions, setQuestions] = useState([]);
	const [answer, setAnswer] = useState({});
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		if (!user || !user.questionnaire) return history.push("/");
		else {
			(async () => {
				try {
					const { data } = await axios.get("/api/user/questionnaire/fetch");
					setQuestions(data.data);
					setLoading(false);
				} catch (error) {
					alert(error.response.data.error);
				}
			})();
		}
	}, []);

	const handleSubmit = async e => {
		const answerArr = Object.values(answer);

		if (answerArr.length !== questions.length)
			return alert("Please select all the answers before checking your result.");
		else {
			setLoading(true);
			const code = user.code;
			const answers = answerArr.map(ans => ans.id);
			try {
				const { data } = await axios.post("/api/user/questionnaire/complete", { code, answers });
				updateQuestionnaire();
				return history.push("/questionnaire/ipp/ensure/result", { score: data.data });
			} catch (error) {
				alert(error.response.data.error);
				setLoading(false);
			}
		}
	};

	if (!user || !user.questionnaire) return <Redirect to="/" />;
	else
		return (
			<div>
				<Nav history={history} match={match} />
				<div style={{ marginTop: "100px" }}>
					{questions && questions.length ? (
						<div>
							{questions.map(question => (
								<div key={question.id} style={{ marginBottom: "20px" }}>
									<div
										style={{
											fontSize: "14px",
											fontWeight: "500",
											display: "flex",
											flexDirection: "row",
											padding: "5px 10px 0px 10px"
										}}
									>
										<img
											src={qi[question.image]}
											alt="vector"
											className="img-fluid"
											style={{ width: "80px", height: "80px" }}
										/>
										<p style={{ margin: "0 4px 0 10px", padding: 0 }}>{question.order}.</p>
										<p style={{ margin: 0, padding: 0 }}>{question.title}</p>
									</div>
									<div
										style={{
											// marginTop: "15px",
											padding: "10px 20px 20px 20px",
											fontSize: "12px",
											fontWeight: "bold"
										}}
									>
										{question.questionnairechoices.map(choice => (
											<div
												key={choice.id}
												style={{
													borderRadius: "10px",
													margin: "5px 0",
													padding: "10px 5px",
													backgroundColor: "#DFE1DF",
													display: "flex",
													flexDirection: "row",
													alignItems: "center"
												}}
												onClick={() => setAnswer({ ...answer, [question.id]: choice })}
											>
												<input
													type="radio"
													checked={answer[question.id] ? answer[question.id].id === choice.id : false}
													readOnly
												/>
												<p style={{ margin: "0 0 0 10px", padding: 0 }}>{choice.title}</p>
											</div>
										))}
									</div>
								</div>
							))}
							<div className="px-3 pb-5">
								<button
									type="button"
									className="big-button btn btn-dark form-control"
									onClick={handleSubmit}
									disabled={loading}
								>
									CHECK MY RESULT
								</button>
							</div>
						</div>
					) : (
						<div className="text-center mt-5">
							<div className="spinner-border text-dark" role="status">
								<span className="sr-only">Loading...</span>
							</div>
						</div>
					)}
				</div>
			</div>
		);
};

const mapStateToProps = state => {
	return {
		user: state.user.user
	};
};

const mapDispatchToProps = dispatch => {
	return {
		updateQuestionnaire: () => dispatch({ type: "UPDATE_QUESTIONNAIRE" })
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Questionnaire);
