import { IS_LOADING , IS_LOADED , SHOW_ERROR , CLEAR_ERROR , SALESREP_LOGIN , SALESREP_LOGOUT } from './type';
import axios from 'axios';

export const salesRepLogin = data => dispatch => {
    dispatch({type : IS_LOADING});
    axios.post('/api/salesRep/login' , data)
    .then(res => {
        if (res.data && res.data.status === "Success") {
            dispatch({type : CLEAR_ERROR});
            dispatch({type : SALESREP_LOGIN , payload : res.data.data});
            dispatch({type : IS_LOADED});
        }
    })
    .catch(err => {
        dispatch({type : SHOW_ERROR , payload : err.response.data.error});
        dispatch({type : IS_LOADED});
    });
};

export const checkSession = () => (dispatch , getState) => {
    const token = getState().salesRep.token;
    if (token) {
        dispatch({type : IS_LOADING});
        axios.post('/api/salesRep/checkSession' , {token})
        .then(res => {
            if (res.data && res.data.status === "Success") {
                dispatch({type : CLEAR_ERROR});
                dispatch({type : SALESREP_LOGIN , payload : res.data.data});
                dispatch({type : IS_LOADED});
            } else {
                dispatch({type : IS_LOADED});
            }
        })
        .catch(err => {
            dispatch({type : SHOW_ERROR , payload : err.response.data.error});
            dispatch({type : IS_LOADED});
        });
    }
};

export const salesRepLogout = () => {
    return {
        type : SALESREP_LOGOUT
    };
};