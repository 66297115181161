import { IS_LOADING, IS_LOADED, SHOW_ERROR, CLEAR_ERROR, SALESREP_LOGIN, SALESREP_LOGOUT } from "../actions/type";

const initState = {
	token: localStorage.getItem("salesRep"),
	salesRep: null,
	isAuthenticated: false,
	isLoading: false,
	error: null
};

const salesRepReducer = (state = initState, action) => {
	// console.log(action);
	switch (action.type) {
		case IS_LOADING:
			return {
				...state,
				isLoading: true
			};

		case IS_LOADED:
			return {
				...state,
				isLoading: false
			};

		case SHOW_ERROR:
			return {
				...state,
				error: action.payload
			};

		case CLEAR_ERROR:
			return {
				...state,
				error: null
			};

		case SALESREP_LOGIN:
			localStorage.setItem("salesRep", action.payload.token);
			return {
				...state,
				isAuthenticated: true,
				salesRep: action.payload.salesRep,
				token: action.payload.token
			};

		case SALESREP_LOGOUT:
			localStorage.removeItem("salesRep");
			return {
				...state,
				isAuthenticated: false,
				salesRep: null,
				token: null
			};

		default:
			return state;
	}
};

export default salesRepReducer;
