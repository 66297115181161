import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import Nav from "../user/userComponents/Navbar";
import goodInfo from "../../assets/images/questionnaire/goodInfo.png";
import badInfo from "../../assets/images/questionnaire/badInfo.png";
import goodMeter from "../../assets/images/questionnaire/goodMeter.png";
import badMeter from "../../assets/images/questionnaire/badMeter.png";

const QuestionnaireResult = ({ match, history }) => {
	useEffect(() => {
		if (!history.location || !history.location.state) return history.push("/user/dashboard");
	}, []);

	if (!history.location || !history.location.state) return <Redirect to="/user/dashboard" />;
	else
		return (
			<div>
				<Nav history={history} match={match} />
				<div
					style={{
						backgroundImage: "linear-gradient(180deg, #001D34 51.16%, #004C6C 100%)",
						color: "white",
						paddingTop: "100px",
						paddingBottom: "20px",
						textAlign: "center",
						borderBottomLeftRadius: "20px",
						borderBottomRightRadius: "20px"
					}}
				>
					<p className="m-0 p-0 font-weight-bold" style={{ fontSize: "28px" }}>
						Your SARC-F Score is:
					</p>
					<img
						src={history.location.state.score > 3 ? badMeter : goodMeter}
						alt="meter"
						className="img-fluid"
						style={{ width: "80%" }}
					/>
					<p className="m-0 p-0 font-weight-bold" style={{ fontSize: "48px" }}>
						{history.location.state.score}
					</p>
					<p className="m-0 p-0 font-weight-bold" style={{ fontSize: "28px" }}>
						{history.location.state.score > 3 ? "" : "NO "}SARCOPENIA RISK
					</p>
				</div>

				<div className="text-center mt-4 px-3">
					<p className="font-weight-bold">WHAT YOUR SCORE MEANS:</p>
					<p>
						{history.location.state.score > 3
							? "SARC-F score equals to or greater than 4 is predict to have Sarcopenia"
							: "SARC-F score below 4 is predict to not have Sarcopenia"}
					</p>

					<p>
						<small>Sarcopenia: An age related, involuntary loss of skeletal muscle mass and strength.</small>
					</p>
					<img
						src={history.location.state.score > 3 ? badInfo : goodInfo}
						alt="info card"
						className="img-fluid mt-4"
						style={{ width: "90%" }}
					/>
				</div>

				<div className="px-3 py-5">
					<button
						type="button"
						className="btn btn-dark form-control big-button"
						onClick={() => history.push("/user/dashboard")}
					>
						REDEEM MY REWARDS
					</button>
				</div>
			</div>
		);
};

export default QuestionnaireResult;
