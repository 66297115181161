import React , { Component } from 'react';
import queryString from 'query-string';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import abbottSmall from '../../assets/images/abbottSmall.png';

const mySwal = withReactContent(Swal);

class Retailer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data : null,
            loading : true,
            submitting : false
        };
    };

    _isMounted = false;

    componentDidMount() {
        const {userId} = queryString.parse(this.props.location.search);
        // console.log({storeId , userId});
        this._isMounted = true;
        if (this._isMounted) {
            axios.post('/api/retailer/checkVoucher' , {subCode : userId})
            .then(res => {
                this.setState({
                    ...this.state,
                    data : res.data.data,
                    loading : false
                });
            })
            .catch(err => {
                this.setState({...this.state , loading : false});
                mySwal.fire("Error" , err.response.data.error , "error");
            });
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
    };

    handleSubmit = e => {
        const {userId} = queryString.parse(this.props.location.search);

        mySwal.fire("Confirm?" , "Confirm to submit request?" , "question")
        .then(result => {
            if (result.isConfirmed) {
                this.setState({...this.state , submitting : true});

                axios.post('/api/retailer/redeem' , {subCode : userId})
                .then(res => {
                    if (res.data && res.data.status === "Success") {
                        this.setState({...this.state, submitting : "Success"});
                        mySwal.fire("Success" , "Request submitted successfully" , "success");
                    }
                })
                .catch(err => {
                    this.setState({...this.state, submitting : false});
                    console.log(err);
                    mySwal.fire("Error" , err.response.data.error , "error");
                });
            } else {
                console.log("Nope");
            }
        })
    };

    render() {
        if (this.state.loading) {
            return (
                <div>
                    <nav className="navbar navbar-dark fixed-top">
                        <div className="ml-auto">
                            <div className="navbar-brand">
                                <img src={abbottSmall} alt="abbott nav" className="img-fluid abbott-logo" />
                            </div>
                        </div>
                    </nav>

                    <div className="text-center body-contents mt-5">
                        <div className="spinner-border text-dark" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            )
        } else if (!this.state.loading && !this.state.data) {
            return (
                <div>
                    <nav className="navbar navbar-dark fixed-top">
                        <div className="ml-auto">
                            <div className="navbar-brand">
                                <img src={abbottSmall} alt="abbott nav" className="img-fluid abbott-logo" />
                            </div>
                        </div>
                    </nav>

                    <div className="text-center body-contents">
                        <h1>Invalid QRCode</h1>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <nav className="navbar navbar-dark fixed-top">
                        <div className="ml-auto">
                            <div className="navbar-brand">
                                <img src={abbottSmall} alt="abbott nav" className="img-fluid abbott-logo" />
                            </div>
                        </div>
                    </nav>
    
                    <div className="body-contents text-center">
                        <div>
                            <h4>{this.state.data.store.storeName}</h4>
                            <h4>{this.state.data.user.name}</h4>
                            <h4>{this.state.data.user.number}</h4>
                        </div>

                        <div id="retailer-box">
                            <p id="retailer-header">Voucher Redemption</p>
                            <p id="retailer-amount">RM{this.state.data.redemption.amount}</p>
                            <p id="retailer-brand">{this.state.data.brand.toUpperCase()} {this.state.data.sku}</p>
                        </div>

                        <div className="mt-4">
                            <p className="m-0 p-0">Note:</p>
                            <p className="m-0 p-0" style={{color : "red"}}>Do not click Confirm on the Voucher will be deemed Used.</p>
                        </div>

                        <div className="mt-5 mb-1">
                            {
                                this.state.submitting === "Success" ? (
                                    <button disabled className="btn big-button form-control">REDEEMED</button>
                                ) : (
                                    this.state.submitting ? (
                                        <div className="text-center">
                                            <div className="spinner-border text-dark" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    ) : (
                                        <button onClick={this.handleSubmit} className="btn big-button form-control">CONFIRM</button>
                                    )
                                )
                            }
                            
                        </div>
                    </div>
                </div>
            );
        }
    };
};

export default Retailer;