import React from 'react';
import {connect} from 'react-redux';
import { salesRepLogout } from '../../../redux/actions/salesRepActions';
import abbottSmall from '../../../assets/images/abbottSmall.png';

const Nav = ({logout , history , match}) => {
    return (
        <nav className="navbar navbar-dark fixed-top">
            {
                match.params.route === "login" ? (null) : <button onClick={e => {logout(); history.push("/salesRep/login")}} className="btn" style={{color : "white"}}><i className="fas fa-sign-out-alt"></i></button>
            }
            <div className="ml-auto">
                <div className="navbar-brand">
                    <img src={abbottSmall} alt="abbott nav" className="img-fluid abbott-logo" />
                </div>
            </div>
        </nav>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        logout : () => dispatch(salesRepLogout())
    };
};

export default connect(null , mapDispatchToProps)(Nav);