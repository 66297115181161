import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { checkSession } from "../../../redux/actions/userActions";
import congrats from "../../../assets/images/congrats.png";
import pediasure600 from "../../../assets/images/pediasure600.jpg";
import pediasure850 from "../../../assets/images/pediasure850.jpg";
import similac600 from "../../../assets/images/similac600.jpg";
import similac900 from "../../../assets/images/similac900.jpg";
import ensure850 from "../../../assets/images/ensure850.jpg";
import glucerna850 from "../../../assets/images/glucerna850.png";
import isomil850 from "../../../assets/images/isomil850.jpg";
import stc820 from "../../../assets/images/stc820.jpg";
import similacMum900 from "../../../assets/images/similacMum900.jpg";
import intellipro3 from "../../../assets/images/intellipro3.png";
import intellipro4 from "../../../assets/images/intellipro4.png";
import ensure400 from "../../../assets/images/ensure400.png";
// import ensureLiq from '../../../assets/images/ensureLiq.png';
import ensureLiq2 from "../../../assets/images/ensureLiq2.png";
import ensureLiq3 from "../../../assets/images/ensureLiq3.png";
import glucerna400 from "../../../assets/images/glucerna400.jpg";
// import glucernaLiq from '../../../assets/images/glucernaLiq.png';
import glucernaLiq2 from "../../../assets/images/glucernaLiq2.png";
import glucernaLiq3 from "../../../assets/images/glucernaLiq3.png";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ascwLogo from "../../../assets/images/ascwLogo1.png";
import ascwScan from "../../../assets/images/ascwScan.png";
// import eVoucher from '../../../assets/images/eVoucher.png';
// import FloatButton from '../FloatButton';

const mySwal = withReactContent(Swal);

const Dashboard = ({ user, checkSession }) => {
  const [sku, setSku] = useState("");
  const userObj = user && user.user && user.user.user ? user.user.user : null;
  const usrStatus = user && user.user ? user.user.usrStatus : null;

  useEffect(() => {
    checkSession();
    if (user.user && user.user.usrStatus === "activated") {
      if (user.user.brand === "pediasure") {
        if (user.user.doctor.promoMechanics !== "default") {
          if (user.user.doctor.sku["600g"]) {
            setSku("600g");
          } else {
            setSku("850g");
          }
        } else {
          setSku("600g");
        }
      } else if (user.user.brand === "similac") setSku("600g");
      else if (user.user.brand === "isomil") setSku("850g");
      else if (user.user.brand === "stc") setSku("820g");
      else if (/ensure/gi.test(user.user.brand)) {
        if (user.user.doctor.promoMechanics !== "default") {
          setSku("400g");
        } else {
          setSku("850g");
        }
      } else if (/glucerna/gi.test(user.user.brand)) {
        if (user.user.doctor.promoMechanics !== "default") {
          setSku("400g");
        } else {
          setSku("850g");
        }
      } else if (user.user.brand === "similacMum") setSku("900g");
      else if (user.user.brand === "intellipro") setSku("step3");
      // else if (user.user.brand === "x") setSku("400g");
      // else if (user.user.brand === "y") setSku("400g");
      // else if (user.user.brand === "a") setSku("400g");
      // else if (user.user.brand === "b") setSku("400g");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userObj && usrStatus === "redeemed") {
      console.log(userObj);
      mySwal
        .fire({
          html: `
					<div>
						<div>
							<img src="${ascwLogo}" alt="ascw-logo" style="width: 100%" />
						</div>

						<div>
							<h1 style="color: black; font-size: 28px; font-weight: bold; margin-top: 10px;">Earn your Carepoints Now!</h1>
						</div>

						<div>
							<p style="color: #004C6C; margin-top: 20px; font-weight: bold;">Find &amp; Scan the QR Code on Abbott Product</p>

							<img src="${ascwScan}" alt="abbott-scan" />
						</div>

						${
              userObj.newAscw
                ? `
								<div style="margin-top: 25px;">
									<p style="text-align: left; color: black; font-size: 14px;">
										<strong>
											<small>Use this password to log in to your My Abbott Cares to earn points and redeem rewards</small>
										</strong>
									</p>

									<input type="text" value="${userObj.ascwPassword}" class="form-control" disabled readonly />
								</div>
								`
                : ""
            }
					</div>
				`,
          confirmButtonText: "Click to Start",
          confirmButtonColor: "#2197D3"
        })
        .then(click => {
          if (click.isConfirmed) window.open(`https://loyalty.abbottmalaysia.com.my/login/${Date.now()}`);
        });
    }
  }, [userObj, usrStatus]);

  if (user.isLoading) {
    return (
      <div className="body-contents text-center">
        <div className="spinner-border text-dark" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  } else if (!user.isAuthenticated) {
    if (user.lastStore) {
      return <Redirect to={`/user/login/${user.lastStore}`} />;
    } else {
      return (
        <div className="body-contents text-center">
          <h5 className="mt-5">Please scan the QRCode to login.</h5>
          {user.error ? <p>{user.error}</p> : null}
        </div>
      );
    }
  } else {
    if (user.user.usrStatus === "redeemed") {
      // redeemed
      const renderImage = () => {
        if (user.user.brand === "pediasure") {
          if (user.user.sku === "600g") {
            return pediasure600;
          } else {
            return pediasure850;
          }
        } else if (user.user.brand === "similac") {
          if (user.user.sku === "600g") {
            return similac600;
          } else {
            return similac900;
          }
        } else if (user.user.brand === "isomil") {
          return isomil850;
        } else if (user.user.brand === "stc") {
          return stc820;
        } else if (/ensure/gi.test(user.user.brand)) {
          if (user.user.doctor.promoMechanics === "foc") {
            return ensureLiq2;
          } else {
            if (user.user.sku === "850g") return ensure850;
            else if (user.user.sku === "400g") return ensure400;
            else if (user.user.sku === "2liq") return ensureLiq2;
            else if (user.user.sku === "3liq") return ensureLiq3;
          }
        } else if (/glucerna/gi.test(user.user.brand)) {
          if (user.user.doctor.promoMechanics === "foc") {
            return glucernaLiq2;
          } else {
            if (user.user.sku === "850g") return glucerna850;
            else if (user.user.sku === "400g") return glucerna400;
            else if (user.user.sku === "2liq") return glucernaLiq2;
            else if (user.user.sku === "3liq") return glucernaLiq3;
          }
        } else if (user.user.brand === "similacMum") {
          return similacMum900;
        } else if (user.user.brand === "intellipro") {
          return intellipro3;
        }
      };
      const renderExpiredDate = date => {
        let generatedDate = new Date(date);
        let expiredDate = new Date(generatedDate.setMonth(generatedDate.getMonth() + 1));

        // let result = new Date(date);
        // result.setDate(result.getDate() + 14);
        const ascMonth = ["JAN", "FEB", "MAR", "APRIL", "MAY", "JUNE", "JULY", "AUG", "SEPT", "OCT", "NOV", "DEC"];
        const day = expiredDate.getDate();
        const month = ascMonth[expiredDate.getMonth()];
        const year = expiredDate.getFullYear();
        return `${day} ${month} ${year}`;
      };

      return (
        <div id="user-dashboard" className="redeemed">
          <h2 className="mt-3">Voucher</h2>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "8px",
              backgroundColor: "#0a1a30",
              borderRadius: "10px",
              margin: "0 5vw"
            }}
          >
            <div style={{ width: "35vw" }}>
              <img src={renderImage()} alt="sku" className="img-fluid" />
            </div>
            <div style={{ textAlign: "left", position: "relative", padding: "5px 10px" }}>
              <h3 style={{ color: "gold" }}>
                {user.user.doctor.promoMechanics === "foc" ? "FOC 2 Units" : `RM ${user.user.redemption.amount} OFF`}
              </h3>
              <p style={{ color: "white" }}>
                On purchase of{" "}
                {/ensure/gi.test(user.user.brand) && user.user.sku === "2liq"
                  ? "Ensure® Gold™ 2xRPB"
                  : /ensure/gi.test(user.user.brand) && user.user.sku === "3liq"
                  ? "Ensure® Gold™ 3xRPB"
                  : /glucerna/gi.test(user.user.brand) && user.user.sku === "2liq"
                  ? "Glucerna Liquid 2xRPB"
                  : /glucerna/gi.test(user.user.brand) && user.user.sku === "3liq"
                  ? "Glucerna Liquid 3xRPB"
                  : user.user.brand.toUpperCase() + " " + user.user.sku}
              </p>
              <p style={{ color: "white" }}>
                Valid until <strong>{renderExpiredDate(user.user.generatedDate)}</strong>
              </p>
            </div>
          </div>

          <div className="mt-4">
            <p>This voucher was redeemed at:</p>

            <div
              style={{
                border: "1px lightgrey solid",
                borderRadius: "10px",
                textAlign: "left",
                padding: "15px 10px",
                width: "80%",
                margin: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
              }}
            >
              <h6 className="m-0 p-0">
                {/hcphospitalretaillist/gi.test(user.user.doctor.code)
                  ? user.user.store.location
                  : user.user.store.storeName}
              </h6>
              {user.user.store.storeType === "ECOMM" ? (
                <p className="m-0 p-0 mt-2">
                  CODE: <strong>{user.user.redemption.voucherCode}</strong>
                </p>
              ) : (
                <p className="m-0 p-0 mt-2">
                  {user.user.store.area}, {user.user.store.state}
                </p>
              )}
            </div>
          </div>

          <div className="mt-4">
            <h6 className="m-0 p-0">
              <strong>DATE: {new Date(user.user.redeemedDate).toDateString()}</strong>
            </h6>
            <h6 className="m-0 p-0 mt-2">
              <strong>TIME: {new Date(user.user.redeemedDate).toLocaleTimeString()}</strong>
            </h6>
          </div>

          <div id="redeemed" className="mt-5" style={{ width: "80%" }}>
            <p className="p-0 m-0">REDEEMED</p>
          </div>

          <p className="mt-2">
            <small>
              Abbott Nutrition Careline at 1800-88-6233
              <br />
              (Monday-Friday 9am-6pm)
            </small>
          </p>

          {/* <div id="voucher-container">
                        <div id="voucher-header">
                            <div id="voucher-image">
                                <img src={renderImage()} alt="eVoucher" className="img-fluid" />
                            </div>
                            <div id="voucher-info">
                                <h5 className="m-0 p-0">DISCOUNT</h5>
                                <h1>RM{user.user.redemption.amount}</h1>
                                <p className="m-0 p-0">On purchase of {(user.user.brand).toUpperCase() + " " + user.user.sku} in {user.user.store.storeName}</p>
                            </div>
                        </div>
    
                        <div id="voucher-store">
                            <p>{user.user.store.storeName}</p>
                        </div>

                        <div id="voucher-footer">   
                            <div id="redeemed">
                                <p className="p-0 m-0">REDEEMED</p>
                            </div>

                            <div id="redeemed-info">
                                {
                                    user.user.store.storeType === "ECOMM"
                                    ? <p>CODE: {user.user.redemption.voucherCode}</p>
                                    : null
                                }
                                <p>DATE: {new Date(user.user.redeemedDate).toDateString()}</p>
                                <p>TIME: {new Date(user.user.redeemedDate).toLocaleTimeString()}</p>
                            </div>
                        </div>
                    </div> */}

          {/* <FloatButton /> */}
        </div>
      );
    } else {
      // activated
      const skuStyle = g => {
        if (g === sku) {
          return { display: "block" };
        } else {
          return { display: "none" };
        }
      };

      const dotColor = g => {
        if (g === sku) {
          return { backgroundColor: "grey" };
        } else {
          return { backgroundColor: "white" };
        }
      };

      return (
        <div id="user-dashboard">
          <div className="px-4">
            <img src={congrats} alt="congratulation" className="img-fluid" />
          </div>

          <p id="big-amount" className="m-0 p-0">
            {user.user.doctor.promoMechanics === "foc" ? "FREE" : "RM10"}
          </p>

          <div>
            <p>
              {user.user.doctor.promoMechanics === "default"
                ? "You are rewarded with RM10 e-Voucher from Abbott Malaysia. Redeem at any preferred participating store!"
                : user.user.doctor.promoMechanics === "voucher"
                ? "You are rewarded with RM10 e-Voucher from Abbott Malaysia. Redeem at our hospital pharmacy now!"
                : user.user.doctor.promoMechanics === "foc" && /ensure/gi.test(user.user.brand)
                ? "2 units x Ensure® Gold™ Liquid Vanilla RPB 220ml upon your purchase of Ensure® Gold™ products. Redeem at our hospital pharmacy now!"
                : "2 units x Glucerna Liquid Vanilla RPB 220ml upon your purchase of Glucerna products. Redeem at our hospital pharmacy now!"}
            </p>
          </div>
          {/* <p>Redeem at any preferred participating store!</p> */}

          <div>
            <h4>Select Pack Size</h4>
            <div id="product-container">
              <div id="flex-product">
                {user.user.brand === "pediasure" && user.user.doctor.promoMechanics === "default" ? (
                  <>
                    <button type="button" className="btn" onClick={() => setSku("600g")}>
                      <i className="fas fa-chevron-circle-left"></i>
                    </button>

                    <div className="products" style={skuStyle("600g")}>
                      <div className="product">
                        <img src={pediasure600} alt="pediasure600g" className="img-fluid" />
                        <p>Pediasure 600g</p>
                      </div>
                    </div>

                    <div className="products" style={skuStyle("850g")}>
                      <div className="product">
                        <img src={pediasure850} alt="pediasure850g" className="img-fluid" />
                        <p>Pediasure 850g</p>
                      </div>
                    </div>

                    <button type="button" className="btn" onClick={() => setSku("850g")}>
                      <i className="fas fa-chevron-circle-right"></i>
                    </button>
                  </>
                ) : user.user.brand === "similac" ? (
                  <>
                    <button type="button" className="btn" onClick={() => setSku("600g")}>
                      <i className="fas fa-chevron-circle-left"></i>
                    </button>

                    <div className="products" style={skuStyle("600g")}>
                      <div>
                        <img src={similac600} alt="pediasure600g" className="img-fluid" />
                        <p>Similac Gain Kid 600g</p>
                      </div>
                    </div>

                    <div className="products" style={skuStyle("900g")}>
                      <div className="product">
                        <img src={similac900} alt="pediasure850g" className="img-fluid" />
                        <p>Similac Gain Plus 900g</p>
                      </div>
                    </div>

                    <button type="button" className="btn" onClick={() => setSku("900g")}>
                      <i className="fas fa-chevron-circle-right"></i>
                    </button>
                  </>
                ) : user.user.brand === "isomil" ? (
                  <>
                    <div className="products">
                      <div className="product">
                        <img src={isomil850} alt="isomil850g" className="img-fluid" />
                        <p>Isomil Plus 850g</p>
                      </div>
                    </div>
                  </>
                ) : user.user.brand === "stc" ? (
                  <>
                    <div className="products">
                      <div className="product">
                        <img src={stc820} alt="stc820g" className="img-fluid" />
                        <p>Similac Total Comfort 820g</p>
                      </div>
                    </div>
                  </>
                ) : /ensure/gi.test(user.user.brand) && user.user.doctor.promoMechanics === "default" ? (
                  <>
                    <div className="products">
                      <div>
                        <img src={ensure850} alt="ensure850g" className="img-fluid" />
                        <p>Ensure&reg; Gold&trade; 850g</p>
                      </div>
                    </div>
                  </>
                ) : /glucerna/gi.test(user.user.brand) && user.user.doctor.promoMechanics === "default" ? (
                  <>
                    <div className="products">
                      <div className="product">
                        <img src={glucerna850} alt="glucerna850g" className="img-fluid" />
                        <p>Glucerna 850g</p>
                      </div>
                    </div>
                  </>
                ) : user.user.brand === "similacMum" ? (
                  <>
                    <div className="products">
                      <div className="product">
                        <img src={similacMum900} alt="similacMum900" className="img-fluid" />
                        <p>Similac Mom 900g</p>
                      </div>
                    </div>
                  </>
                ) : user.user.brand === "intellipro" ? (
                  <>
                    <button type="button" className="btn" onClick={() => setSku("step3")}>
                      <i className="fas fa-chevron-circle-left"></i>
                    </button>

                    <div className="products" style={skuStyle("step3")}>
                      <div className="product">
                        <img src={intellipro3} alt="intellipro3" className="img-fluid" />
                        <p>Intellipro Step3</p>
                      </div>
                    </div>

                    <div className="products" style={skuStyle("step4")}>
                      <div className="product">
                        <img src={intellipro4} alt="intellipro4" className="img-fluid" />
                        <p>Intellipro Step4</p>
                      </div>
                    </div>

                    <button type="button" className="btn" onClick={() => setSku("step4")}>
                      <i className="fas fa-chevron-circle-right"></i>
                    </button>
                  </>
                ) : /ensure/gi.test(user.user.brand) && user.user.doctor.promoMechanics === "voucher" ? (
                  <>
                    <button type="button" className="btn" onClick={() => setSku("400g")}>
                      <i className="fas fa-chevron-circle-left"></i>
                    </button>

                    <div className="products" style={skuStyle("400g")}>
                      <div className="product">
                        <img src={ensure400} alt="ensure400" className="img-fluid" />
                        <p>Ensure&reg; Gold&trade; 400g</p>
                      </div>
                    </div>

                    <div className="products" style={skuStyle("2liq")}>
                      <div className="product">
                        <img src={ensureLiq2} alt="2liq" className="img-fluid" />
                        <p>Ensure&reg; Gold&trade; 2xRPB</p>
                      </div>
                    </div>

                    <button type="button" className="btn" onClick={() => setSku("2liq")}>
                      <i className="fas fa-chevron-circle-right"></i>
                    </button>
                  </>
                ) : /glucerna/gi.test(user.user.brand) && user.user.doctor.promoMechanics === "voucher" ? (
                  <>
                    <button type="button" className="btn" onClick={() => setSku("400g")}>
                      <i className="fas fa-chevron-circle-left"></i>
                    </button>

                    <div className="products" style={skuStyle("400g")}>
                      <div className="product">
                        <img src={glucerna400} alt="glucerna400" className="img-fluid" />
                        <p>Glucerna Vanilla 400g</p>
                      </div>
                    </div>

                    <div className="products" style={skuStyle("2liq")}>
                      <div className="product">
                        <img src={glucernaLiq2} alt="glucernaLiq2" className="img-fluid" />
                        <p>Glucerna Liquid 2xRPB</p>
                      </div>
                    </div>

                    <button type="button" className="btn" onClick={() => setSku("2liq")}>
                      <i className="fas fa-chevron-circle-right"></i>
                    </button>
                  </>
                ) : /ensure/gi.test(user.user.brand) && user.user.doctor.promoMechanics === "foc" ? (
                  <>
                    {/* <button type="button" className="btn" onClick={() => setSku("400g")}>
                      <i className="fas fa-chevron-circle-left"></i>
                    </button> */}

                    <div className="products" style={skuStyle("400g")}>
                      <div className="product">
                        <img src={ensure400} alt="ensure400" className="img-fluid" />
                        <p>Ensure&reg; Gold&trade; 400g</p>
                      </div>
                    </div>

                    {/* <div className="products" style={skuStyle("3liq")}>
                      <div className="product">
                        <img src={ensureLiq3} alt="ensureLiq3" className="img-fluid" />
                        <p>Ensure&reg; Gold&trade; 3xRPB</p>
                      </div>
                    </div> */}

                    {/* <button type="button" className="btn" onClick={() => setSku("3liq")}>
                      <i className="fas fa-chevron-circle-right"></i>
                    </button> */}
                  </>
                ) : /glucerna/gi.test(user.user.brand) && user.user.doctor.promoMechanics === "foc" ? (
                  <>
                    {/* <button type="button" className="btn" onClick={() => setSku("400g")}>
                      <i className="fas fa-chevron-circle-left"></i>
                    </button> */}

                    <div className="products" style={skuStyle("400g")}>
                      <div className="product">
                        <img src={glucerna400} alt="glucerna400" className="img-fluid" />
                        <p>Glucerna Vanilla 400g</p>
                      </div>
                    </div>

                    {/* <div className="products" style={skuStyle("3liq")}>
                      <div className="product">
                        <img src={glucernaLiq3} alt="glucernaLiq3" className="img-fluid" />
                        <p>Glucerna Liquid 3xRPB</p>
                      </div>
                    </div> */}

                    {/* <button type="button" className="btn" onClick={() => setSku("3liq")}>
                      <i className="fas fa-chevron-circle-right"></i>
                    </button> */}
                  </>
                ) : user.user.brand === "pediasure" &&
                  user.user.doctor.promoMechanics !== "default" &&
                  user.user.doctor.sku["600g"] ? (
                  <>
                    <div className="products">
                      <div className="product">
                        <img src={pediasure600} alt="pediasure600g" className="img-fluid" />
                        <p>Pediasure 600g</p>
                      </div>
                    </div>
                  </>
                ) : user.user.brand === "pediasure" &&
                  user.user.doctor.promoMechanics !== "default" &&
                  user.user.doctor.sku["850g"] ? (
                  <>
                    <div className="products">
                      <div className="product">
                        <img src={pediasure850} alt="pediasure850g" className="img-fluid" />
                        <p>Pediasure 850g</p>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>

            {(user.user.brand === "pediasure" || user.user.brand === "similac") &&
            user.user.doctor.promoMechanics === "default" ? (
              <div id="circle-dot-container">
                <div className="circle-dot" style={dotColor("600g")} onClick={() => setSku("600g")}></div>
                <div
                  className="circle-dot"
                  style={dotColor(user.user.brand === "pediasure" ? "850g" : "900g")}
                  onClick={() => setSku(user.user.brand === "pediasure" ? "850g" : "900g")}
                ></div>
              </div>
            ) : (/ensure/gi.test(user.user.brand) || /glucerna/gi.test(user.user.brand)) &&
              user.user.doctor.promoMechanics === "voucher" ? (
              <div id="circle-dot-container">
                <div className="circle-dot" style={dotColor("400g")} onClick={() => setSku("400g")}></div>
                <div className="circle-dot" style={dotColor("2liq")} onClick={() => setSku("2liq")}></div>
              </div>
            ) : (/ensure/gi.test(user.user.brand) || /glucerna/gi.test(user.user.brand)) &&
              user.user.doctor.promoMechanics === "foc" ? (
              <div id="circle-dot-container">
                <div className="circle-dot" style={dotColor("400g")} onClick={() => setSku("400g")}></div>
                {/* {/glucerna/gi.test(user.user.brand) ? <div className="circle-dot" style={dotColor("3liq")} onClick={() => setSku("3liq")}></div> : null} */}
              </div>
            ) : null}
          </div>

          <div style={{ marginTop: "5vh" }}>
            <Link
              to={
                user.user.usrStatus === "activated"
                  ? {
                      pathname: "/user/redeem",
                      state: { selected: sku, promoMechanics: user.user.doctor.promoMechanics }
                    }
                  : user.user.usrStatus === "redeemed"
                  ? {
                      pathname: "/user/checkout",
                      state: { selected: sku, promoMechanics: user.user.doctor.promoMechanics }
                    }
                  : {
                      pathname: "/user/voucher",
                      state: { selected: sku, promoMechanics: user.user.doctor.promoMechanics }
                    }
              }
              className="btn form-control big-button-alt"
            >
              NEXT
            </Link>
            <p className="mt-2">
              <small>
                Abbott Nutrition Careline at 1800-88-6233
                <br />
                (Monday-Friday 9am-6pm)
              </small>
            </p>
          </div>

          {/* <FloatButton /> */}
        </div>
      );
    }
  }
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = dispatch => {
  return {
    checkSession: () => dispatch(checkSession())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
