import React from 'react';
import { Switch , Route } from 'react-router-dom';
import { connect } from 'react-redux';

import Nav from './userComponents/Navbar';
import Login from './userComponents/Login';
import Dashboard from './userComponents/Dashboard';
import Redeem from './userComponents/Redeem';
// import Voucher from './userComponents/Voucher';
import Checkout from './userComponents/Checkout';
import Terms from './userComponents/Terms';

// splashscreens
// import ensure from '../../assets/images/splashscreens/ensure.png';
// import glucerna from '../../assets/images/splashscreens/glucerna.png';
// import isomil from '../../assets/images/splashscreens/isomil.png';
// import pediasure from '../../assets/images/splashscreens/pediasure.png';
// import similac from '../../assets/images/splashscreens/similac.png';
// import stc from '../../assets/images/splashscreens/stc.png';

const User = ({ history , match }) => {
    // const [trigger, setTrigger] = useState(false)

    // useEffect(() => {
    //     if (showImage) {
    //         setTrigger(true);
    //         setTimeout(() => setTrigger(false) , 3000);
    //     };
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [showImage]);


    // if (trigger) {
    //     const renderImages = () => {
    //         if (sku === "pediasure") return pediasure;
    //         else if (sku === "similac") return similac;
    //         else if (sku === "stc") return stc;
    //         else if (sku === "isomil") return isomil;
    //         else if (sku === "ensure") return ensure;
    //         else if (sku === "glucerna") return glucerna;
    //     };

    //     return (
    //         <div>
    //             <img src={renderImages()} alt="splashsceen" className="img-fluid" style={{ height: "100vh", width: "100vw" }} />
    //         </div>
    //     )
    // } else {
        return (
            <div>
                <Nav history={history} match={match} />
                <Switch>
                    <Route path="/user/login/:code" component={Login} />
                    <Route exact path="/user/dashboard" component={Dashboard} />
                    <Route exact path="/user/redeem" component={Redeem} />
                    {/* <Route exact path="/user/voucher" component={Voucher} /> */}
                    <Route exact path="/user/checkout" component={Checkout} />
                    <Route exact path="/user/terms" component={Terms} />
                </Switch>
            </div>
        );
    // }
};

const mapStateToProps = state => {
    return {
        showImage: state.code.show,
        sku: state.code.sku
    };
};

export default connect(mapStateToProps)(User);