import {
	CHECK_NUMBER,
	REGISTER_USER,
	LOGIN_USER,
	IS_LOADING,
	IS_LOADED,
	SHOW_ERROR,
	CLEAR_ERROR,
	LOGOUT_USER,
	SAVE_STORE,
	FETCH_CODE,
	CODE_ERROR,
	CHECK_REGISTER
} from "./type";
import axios from "axios";

export const fetchCode = data => dispatch => {
	axios
		.post("/api/user/fetchCode", data)
		.then(res => {
			dispatch({ type: FETCH_CODE, payload: res.data });
		})
		.catch(err => dispatch({ type: CODE_ERROR }));
};

export const checkScanned = (data, callback) => dispatch => {
	dispatch({ type: SAVE_STORE, payload: data.code });

	axios
		.post("/api/user/checkScan", data)
		.then(res => callback(res.data.ipp, res.data.brand, res.data.voucher))
		.catch(err => console.log({ err }));
};

export const checkNumber = number => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/user/checkNumber", number)
		.then(res => {
			dispatch({ type: CLEAR_ERROR });
			dispatch({ type: CHECK_NUMBER, payload: res.data });
			dispatch({ type: IS_LOADED });
		})
		.catch(err => {
			dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
			dispatch({ type: IS_LOADED });
		});
};

export const registerUser = data => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/user/register", data)
		.then(res => {
			dispatch({ type: CLEAR_ERROR });
			dispatch({ type: REGISTER_USER, payload: res.data.identity });
			dispatch({ type: IS_LOADED });
		})
		.catch(err => {
			dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
			dispatch({ type: IS_LOADED });
		});
};

export const checkRegister = data => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/user/checkRegister", data)
		.then(res => {
			dispatch({ type: CLEAR_ERROR });
			dispatch({ type: CHECK_REGISTER, payload: { status: res.data.status, isAscw: res.data.isAscw } });
			dispatch({ type: IS_LOADED });
		})
		.catch(err => {
			dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
			dispatch({ type: IS_LOADED });
		});
};

export const adultRegister = data => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/user/adultRegister", data)
		.then(res => {
			dispatch({ type: CLEAR_ERROR });
			dispatch({ type: LOGIN_USER, payload: res.data });
			dispatch({ type: IS_LOADED });
		})
		.catch(err => {
			dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
			dispatch({ type: IS_LOADED });
		});
};

export const loginUser = (data, callback) => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/user/login", data)
		.then(res => {
			if (res.data.ascwToken) {
				dispatch({ type: "ASCW_TOKEN", payload: res.data });
				callback(res.data.ascwToken);
			} else {
				dispatch({ type: CLEAR_ERROR });
				dispatch({ type: LOGIN_USER, payload: res.data });
				dispatch({ type: IS_LOADED });
			}
		})
		.catch(err => {
			dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
			dispatch({ type: IS_LOADED });
		});
};

export const ascwLogin = data => dispatch => {
	console.log("ASCW LOGIN", data);
	axios
		.post("/api/user/v2/ascw/add", data)
		.then(res => {
			dispatch({ type: CLEAR_ERROR });
			dispatch({ type: "LOGIN" });
			dispatch({ type: IS_LOADED });
		})
		.catch(err => {
			dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
			dispatch({ type: IS_LOADED });
		});
};

export const checkSession = () => (dispatch, getState) => {
	const token = getState().user.token;
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/user/checkSession", { token })
		.then(res => {
			dispatch({ type: CLEAR_ERROR });
			dispatch({ type: LOGIN_USER, payload: res.data });
			dispatch({ type: IS_LOADED });
		})
		.catch(err => {
			// dispatch({type : SHOW_ERROR , payload : err.response.data.error});
			dispatch({ type: IS_LOADED });
		});
};

export const reclaimVoucher = data => dispatch => {
	dispatch({ type: IS_LOADING });
	axios
		.post("/api/user/reclaim", data)
		.then(res => {
			dispatch({ type: CLEAR_ERROR });
			dispatch({ type: "UPDATE_USER", payload: res.data.data });
			dispatch({ type: IS_LOADED });
		})
		.catch(err => {
			console.log(err);
			dispatch({ type: SHOW_ERROR, payload: err.response.data.error });
			dispatch({ type: IS_LOADED });
		});
};

export const logoutUser = () => {
	return {
		type: LOGOUT_USER
	};
};
