import {IS_LOADING , IS_LOADED , SHOW_ERROR , CLEAR_ERROR , ADMIN_LOGIN , ADMIN_LOGOUT} from '../actions/type';

const initState = {
    isLoading : false,
    isAuthenticated : false,
    role : null,
    error : null,
    token : localStorage.getItem("portal")
};

const adminReducer = (state = initState , action) => {
    switch(action.type) {
        case IS_LOADING:
            return {
                ...state,
                isLoading : true
            };
        case IS_LOADED:
            return {
                ...state,
                isLoading : false
            };

        case SHOW_ERROR:
            return {
                ...state,
                error : action.payload
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error : null
            };
        
        case ADMIN_LOGIN:
            localStorage.setItem("portal" , action.payload.token);
            return {
                ...state,
                isAuthenticated : true,
                role : action.payload.role,
                token : action.payload.token
            };

        case ADMIN_LOGOUT:
            localStorage.removeItem("portal");
            return {
                ...state,
                isAuthenticated : false,
                role : null,
                token : null,
            };

        default:
            return state;
    };
};

export default adminReducer;