import React from 'react';
import { Switch , Route } from 'react-router-dom';

import Nav from './salesRepComponents/Navbar';
import Login from './salesRepComponents/Login';
import Dashboard from './salesRepComponents/Dashboard';

const SalesRep = ({history , match}) => {
    return (
        <div>
            <Nav history={history} match={match} />
            <Switch>
                <Route exact path="/salesRep/login" component={Login} />
                <Route exact path="/salesRep/dashboard" component={Dashboard} />
            </Switch>
        </div>
    );
};

export default SalesRep;