const Terms = () => {
	return (
		<div className="body-contents">
			<h1>Abbott Care Pack Terms &amp; Conditions</h1>
			<div>
				<ol>
					<li>Name and Phone Number are required for customer registration. Email is optional.</li>
					<li>Customers are allowed to use the same phone number to register for different participating brands.</li>
					<li>
						Customers are allowed to used the RM10 voucher for the following participating brands:
						<br />
						PediaSure Base, PediaSure Upage, Similac Total Comfort, Similac Intelli-Pro, Isomil Plus, Similac Gain Plus
						Gold, Similac Mom, Ensure® Gold™ and Glucerna.
					</li>
					<li>Customers can use the RM10 vouchers at both online and offline participating stores.</li>
					<li>The RM10 voucher is not applicable for any other in-store promotion.</li>
					<li>The RM10 voucher is valid for a month after generation date.</li>
					<li>
						Customers are only allowed to redeem the RM10 voucher during the campaign duration 1st January to 31st
						December {new Date().getFullYear()}
					</li>
					<li>
						Abbott Laboratories (M) Sdn Bhd. reserves the right to amend, modify, update, change or terminate the Abbott
						Care Pack Programme or any portion of the Terms &amp; Conditions from time to time without any prior
						notification. Any amendment will be effective immediately
					</li>
					{/* <li>The rewards are based on <strong>First Come First Served</strong> basis, subject to availability.</li> */}
				</ol>
			</div>
		</div>
	);
};

export default Terms;
