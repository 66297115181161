import { combineReducers } from 'redux';
import salesRepReducer from './salesRepReducer';
import userReducer from './userReducer';
import adminReducer from './adminReducer';
import codeReducer from './codeReducer';

const rootReducer = combineReducers({
    salesRep : salesRepReducer,
    user : userReducer,
    admin : adminReducer,
    code: codeReducer
});

export default rootReducer;