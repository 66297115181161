import axios from 'axios';
import React , {Component} from 'react';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const mySwal = withReactContent(Swal);

class OtpButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timer : 60,
            disabled : true
        };
    };

    timer;

    componentDidMount() {
        this.timer = setInterval(() => {
            this.setState({
                timer : this.state.timer - 1
            });
            if (this.state.timer === 0) {
                this.setState({
                    ...this.state,
                    disabled : false
                })
                clearInterval(this.timer);
                this.timer = null;
            }
        } , 1000);
    };

    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
    };

    handleResend = e => {
        e.preventDefault();
        this.setState({...this.state , disabled : true});
        const number = `+6${this.props.number}`;
        const voucherCode = this.props.voucherCode
        axios.post('/api/user/resendOtp' , {number, voucherCode})
        .then(res => {
            if (res.data && res.data.status === "Success") {
                this.setState({
                    ...this.state,
                    timer : 60
                });
                this.timer = setInterval(() => {
                    this.setState({
                        ...this.state,
                        timer : this.state.timer - 1
                    });
                    if (this.state.timer === 0) {
                        this.setState({
                            ...this.state,
                            disabled : false
                        });
                        clearInterval(this.timer);
                        this.timer = null;
                    }
                } , 1000);
            }
        })
        .catch(err => {
            this.setState({
                ...this.state,
                timer : 60
            });
            this.timer = setInterval(() => {
                this.setState({
                    ...this.state,
                    timer : this.state.timer - 1
                });
                if (this.state.timer === 0) {
                    this.setState({
                        ...this.state,
                        disabled : false
                    });
                    clearInterval(this.timer);
                    this.timer = null;
                }
            } , 1000);
            mySwal.fire("Error" , err.response.data.error , "error");
        });
    };

    render() {
        console.log("number : " , this.props.number);
        return <button onClick={this.handleResend} type="button" className="btn btn-dark" disabled={this.state.disabled}>RESEND OTP {this.state.timer ? this.state.timer : null}</button>
    };
}

export default OtpButton;