import React from "react";
import "./App.css";

import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
// import { PwaUpdater } from "pwa-updater";

import store from "./redux/store/store";

import SalesRep from "./components/salesRep/SalesRep";
import User from "./components/user/User";
// import Admin from './components/admin/Admin';
import Retailer from "./components/retailer/Retailer";
import Faq from "./components/faq/Faq";
import QuestionStart from "./components/questionnaire/QuestionStart";
import Questionnaire from "./components/questionnaire/Questionnaire";
import QuestionnaireResult from "./components/questionnaire/QuestionnaireResult";

function App() {
	// useEffect(() => {
	//   if (window.location.pathname.includes("/admin")) window.location.href = `${window.location.origin}/admin/login`;
	// }, []);
	return (
		<Provider store={store}>
			<BrowserRouter>
				{/* <PwaUpdater notify={false} /> */}
				<div className="App">
					<Switch>
						<Route exact path="/salesRep/:route" component={SalesRep} />
						<Route path="/user/:route" component={User} />
						{/* <Route path="/admin" component={Admin} /> */}
						<Route exact path="/retailer" component={Retailer} />
						<Route exact path="/faq/:category" component={Faq} />
						<Route exact path="/questionnaire/ipp/ensure/start" component={QuestionStart} />
						<Route exact path="/questionnaire/ipp/ensure" component={Questionnaire} />
						<Route exact path="/questionnaire/ipp/ensure/result" component={QuestionnaireResult} />
						<Redirect to="/user/dashboard" />
					</Switch>
				</div>
			</BrowserRouter>
		</Provider>
	);
}

export default App;
