export const IS_LOADING = "IS_LOADING";
export const IS_LOADED = "IS_LOADED";
export const SHOW_ERROR = "SHOW_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const SALESREP_LOGIN = "SALESREP_LOGIN";
export const SALESREP_LOGOUT = "SALESREP_LOGOUT"

export const CHECK_NUMBER = "CHECK_NUMBER";
export const CHECK_REGISTER = "CHECK_REGISTER";
export const REGISTER_USER = "REGISTER_USER";
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const SAVE_STORE = "SAVE_STORE";

export const ADMIN_LOGIN = "ADMIN_LOGIN";
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";

export const FETCH_CODE = "FETCH_CODE";
export const CODE_ERROR = "CODE_ERROR";