import React , { useState , useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { salesRepLogin , checkSession } from '../../../redux/actions/salesRepActions';

const Login = ({salesRep , salesRepLogin , checkSession}) => {
    useEffect(() => {
        checkSession();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    } , []);

    const [number , setNumber] = useState("");

    const handleChange = e => {
        setNumber(e.target.value);
    };

    const handleSubmit = e => {
        e.preventDefault();
        salesRepLogin({number});
    };

    if (salesRep.isAuthenticated) {
        return <Redirect to="/salesRep/dashboard" />
    } else {

        return (
            
            <div className="body-contents text-center">
                <h3 className="mt-5">SALES REP LOG IN</h3>
    
                <form className="mt-4" onSubmit={e => handleSubmit(e)}>
                    <div className="form-group px-3">
                        <input type="text" id="number" name="number" placeholder="Mobile Number" className="form-control input-box" value={number} onChange={e => handleChange(e)} required />
                        <div className="text-left">
                            <small className="text-muted">Example: 0198274923</small>
                        </div>
                    </div>
    
                    {
                        salesRep.error ? (
                            <div className="alert alert-warning" role="alert">
                                <strong>{salesRep.error}</strong>
                            </div>
                        ) : (null)
                    }
    
                    <div className="mb-1 text-center" style={{marginTop : "40vh"}}>
                        {
                            salesRep.isLoading ? (
                                <div className="spinner-border text-dark" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            ) : (
                                <input type="submit" value="LOG IN" className="btn form-control big-button" />
                            )
                        }
                    </div>
                </form>
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        salesRep : state.salesRep
    };
};

const mapDispatchToProps = dispatch => {
    return {
        salesRepLogin : data => dispatch(salesRepLogin(data)),
        checkSession : () => dispatch(checkSession())
    };
};

export default connect(mapStateToProps , mapDispatchToProps)(Login);