import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import adultLogo from "../../assets/images/adultLogo.png";
import kidsLogo from "../../assets/images/kidsLogo.png";

import enFaq from "../../assets/pdf/enFaq.pdf";
import chFaq from "../../assets/pdf/chFaq.pdf";
import bmFaq from "../../assets/pdf/bmFaq.pdf";

class Faq extends Component {
	constructor(props) {
		super(props);
		this.state = {
			lang: "en"
		};
	}

	render() {
		const { category } = this.props.match.params;
		// adult = dark blue
		// paeds = light blue

		if (category === "paeds" || category === "adult" || category === "retailer") {
			const buttonStyle = val => {
				if (category === "paeds") {
					if (val === this.state.lang) {
						return { backgroundColor: "#17749d", color: "white", fontWeight: "bold", border: "1px #b7b5b6 solid", overflow: "hidden" };
					}
				} else {
					// adult
					if (val === this.state.lang) {
						return { backgroundImage: "linear-gradient(90deg, #c39e47, #e8ce83 , #eed993)", color: "#0a1a30", fontWeight: "bold", border: "1px #b7b5b6 solid", overflow: "hidden" };
					}
				}
			};

			const renderFrame = () => {
				if (category === "paeds") {
					if (this.state.lang === "en") {
						return (
							<iframe
								width="560"
								height="315"
								src="https://www.youtube.com/embed/cKsvsD1c6Pg"
								title="Paeds English Tutorial"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
							></iframe>
						);
					} else if (this.state.lang === "ch") {
						return (
							<iframe
								width="560"
								height="315"
								src="https://www.youtube.com/embed/N0mjqtN4qqk"
								title="Paeds Chinese Tutorial"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
							></iframe>
						);
					} else if (this.state.lang === "bm") {
						return (
							<iframe
								width="560"
								height="315"
								src="https://www.youtube.com/embed/gwM1NaZU8P4"
								title="Paeds Bahasa Tutorial"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
							></iframe>
						);
					}
				} else if (category === "adult") {
					if (this.state.lang === "en") {
						return (
							<iframe
								width="560"
								height="315"
								src="https://www.youtube.com/embed/U1sWV8irJg8"
								title="Adult English Tutorial"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
							></iframe>
						);
					} else if (this.state.lang === "ch") {
						return (
							<iframe
								width="560"
								height="315"
								src="https://www.youtube.com/embed/ctE0iQGWJe0"
								title="Adult Chinese Tutorial"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
							></iframe>
						);
					} else if (this.state.lang === "bm") {
						return (
							<iframe
								width="560"
								height="315"
								src="https://www.youtube.com/embed/Y8HW1UMnVMs"
								title="Adult Bahasa Tutorial"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
							></iframe>
						);
					}
				} else if (category === "retailer") {
					if (this.state.lang === "en") {
						return (
							<iframe
								width="560"
								height="315"
								src="https://www.youtube.com/embed/CSRDyfv3rPo"
								title="Paeds English Tutorial"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
							></iframe>
						);
					} else if (this.state.lang === "ch") {
						return (
							<iframe
								width="560"
								height="315"
								src="https://www.youtube.com/embed/zMlYfTZl3lk"
								title="Paeds Chinese Tutorial"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
							></iframe>
						);
					} else if (this.state.lang === "bm") {
						return (
							<iframe
								width="560"
								height="315"
								src="https://www.youtube.com/embed/eht3fNeiJpE"
								title="Paeds Bahasa Tutorial"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
							></iframe>
						);
					}
				}
			};

			return (
				<div id={category === "adult" ? "adultLayout" : category === "paeds" ? "paedsLayout" : "retailerLayout"}>
					<div id="logo-container" className="mt-3">
						<img src={category === "paeds" ? kidsLogo : adultLogo} alt="main logo" className="img-fluid m-auto" style={{ width: "80%" }} />
					</div>

					<div>
						<div style={{ textAlign: "right" }}>
							<div id="language-container" style={category === "paeds" ? { border: "1px #47a7d1 solid" } : { border: "1px white solid" }}>
								<button style={buttonStyle("en")} onClick={() => this.setState({ lang: "en" })} className="language-button leftButton">
									ENGLISH
								</button>
								<button style={buttonStyle("ch")} onClick={() => this.setState({ lang: "ch" })} className="language-button">
									华语
								</button>
								<button style={buttonStyle("bm")} onClick={() => this.setState({ lang: "bm" })} className="language-button rightButton">
									BAHASA
								</button>
							</div>
						</div>

						<div id="video-container">{renderFrame()}</div>
					</div>

					<div style={{ marginTop: "10vh" }}>
						<a href={this.state.lang === "en" ? enFaq : this.state.lang === "ch" ? chFaq : bmFaq} className="btn form-control big-button-alt">
							{this.state.lang === "en" ? "Read FAQ" : this.state.lang === "ch" ? "阅读常见问题" : "Baca Soalan Lazim"}
						</a>
					</div>
				</div>
			);
		} else {
			return <Redirect to="/" />;
		}
	}
}

export default Faq;
