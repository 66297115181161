import {
	IS_LOADING,
	IS_LOADED,
	SHOW_ERROR,
	CLEAR_ERROR,
	CHECK_NUMBER,
	REGISTER_USER,
	LOGIN_USER,
	LOGOUT_USER,
	SAVE_STORE,
	CHECK_REGISTER
} from "../actions/type";

const initState = {
	token: localStorage.getItem("user"),
	identity: null,
	action: "check",
	isLoading: false,
	isAuthenticated: false,
	error: null,
	user: null,
	stores: null,
	lastStore: localStorage.getItem("lastStore"),
	tempName: "",
	tempNumber: "",
	status: null,
	isAscw: null
};

const userReducer = (state = initState, action) => {
	switch (action.type) {
		case IS_LOADING:
			return {
				...state,
				isLoading: true
			};

		case IS_LOADED:
			return {
				...state,
				isLoading: false
			};

		case SHOW_ERROR:
			return {
				...state,
				error: action.payload
			};

		case CLEAR_ERROR:
			return {
				...state,
				error: null
			};

		case CHECK_NUMBER:
		case REGISTER_USER:
			return {
				...state,
				identity: action.payload.identity,
				action: action.payload.identity === "NEW" ? "register" : "otp",
				tempName: action.payload.name || "",
				tempNumber: action.payload.number || ""
			};

		case CHECK_REGISTER:
			return {
				...state,
				action: "login",
				status: action.payload.status
			};

		case "ASCW_TOKEN":
			localStorage.setItem("user", action.payload.token);
			return {
				...state,
				isAscw: action.payload.isAscw,
				token: action.payload.token,
				user: action.payload.data,
				stores: action.payload.stores
			};

		case "LOGIN":
			return {
				...state,
				isAuthenticated: true
			};

		case LOGIN_USER:
			localStorage.setItem("user", action.payload.token);
			return {
				...state,
				token: action.payload.token,
				user: action.payload.data,
				stores: action.payload.stores,
				isAuthenticated: true
			};

		case "UPDATE_QUESTIONNAIRE":
			return {
				...state,
				user: {
					...state.user,
					questionnaire: false
				}
			};

		case LOGOUT_USER:
			localStorage.removeItem("user");
			return {
				...state,
				token: null,
				user: null,
				identity: null,
				action: "check",
				isAuthenticated: false,
				error: null,
				stores: null
			};

		case "UPDATE_USER":
			return {
				...state,
				user: { ...state.user, ...action.payload, doctor: state.user.doctor }
			};

		case "UPSERT_USER":
			return {
				...state,
				user: { ...state.user, voucherData: action.payload }
				// voucher : action.payload
			};

		case SAVE_STORE:
			localStorage.setItem("lastStore", action.payload);
			return {
				...state,
				lastStore: action.payload
			};

		default:
			return state;
	}
};

export default userReducer;
